import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { BorderButton } from "../../components/Button/Button";
import { localeLink } from "../../helpers/localeLink";
import { SlipTransition } from "../SlipTransition/SlipTransition";
import * as styles from "./OverFooter.module.css";

export const OverFooter = ({
  title,
  buttonLink,
  buttonLabel,
  image,
  color = "rgba(28, 28, 28, 0.4)",
  children,
}) => {
  return (
    <div
      style={{
        background: `linear-gradient(180deg, ${color} 35.83%, rgba(79, 79, 79, 0.2) 114.35%), url("${image}") no-repeat bottom / cover`,
      }}
      className={styles.wrapper}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <SlipTransition lines={[title]} color="#fff" />
          </div>
          <div className={styles.tricksPaddingButton}>
            <Link to={localeLink(buttonLink)}>
              <BorderButton label={buttonLabel} color="#fff" />
            </Link>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

OverFooter.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
