import { MDXProvider } from "@mdx-js/react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { Link } from "gatsby";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useState } from "react";

import { BorderButton } from "../../../../../../components/Button/Button";
import { Closure } from "../../../../../../components/Closure/Closure";
import { localeLink } from "../../../../../../helpers/localeLink";
import { UniversPageContext } from "../../../../../../helpers/pagesContext";
import { CONTACT } from "../../../../../../helpers/route";
import * as styles from "./JobDescription.module.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  () => ({
    flexDirection: "row-reverse",
    padding: "0 75px",
    ["@media (max-width:  1199.98px)"]: {
      padding: "0 20px",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      ["@media (max-width: 575.98px)"]: {
        margin: 0,
      },
    },
    "& .MuiAccordionSummary-content > .MuiTypography-body1": {
      fontFamily: "Futura",
      fontWeight: "100",
      fontSize: 24,
      letterSpacing: "0.04em",
      color: "#8F8F8F",
      borderBottom: "4px solid rgb(236 236 236)",
      ["@media (max-width: 991.98px)"]: {
        fontSize: 18,
      },
      ["@media (max-width: 767.98px)"]: {
        color: "rgba(244, 244, 244, 0.8)",
        borderBottom: "4px solid rgba(196, 196, 196, 0.29)",
      },
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 75px",
  fontFamily: "Futura",
  fontSize: 16,
  lineHeight: "150%",
  color: "#676767",
  ["@media (max-width: 1199.98px)"]: {
    padding: "0 20px",
  },
  ["@media (max-width: 991.98px)"]: {
    fontSize: 14,
    lineHeight: "125%",
  },
  ["@media (max-width: 767.98px)"]: {
    color: "#ffffff",
  },
}));

export const JobDescription = ({ file, title, modalData, handleClose }) => {
  const sm = useMediaQuery("(max-width: 767.98px)");
  const { missions, competences, evolution, talent } = modalData;
  const [expanded, setExpanded] = useState("panel1");
  const [competencesExpanded, setCompetencesExpanded] = useState({
    name: "hard",
    data: competences.hard,
  });
  const { mission, competence, hard, soft, evolutions, program, consult } =
    useContext(UniversPageContext);

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Closure handleClose={handleClose} />
      <div className={styles.grid}>
        <div className={styles.titleMobile}>{title}</div>
        <div className={styles.accordion}>
          <Accordion
            sx={expanded === "panel1" ? { overflow: "auto" } : ""}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                className={clsx(
                  expanded === "panel1" && !sm && styles.titleActive,
                  expanded === "panel1" && sm && styles.titleActiveMobile
                )}
              >
                {mission}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MDXProvider>
                <MDXRenderer>{missions.childMdx.body}</MDXRenderer>
              </MDXProvider>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={expanded === "panel2" ? { overflow: "auto" } : ""}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography
                className={clsx(
                  expanded === "panel2" && !sm && styles.titleActive,
                  expanded === "panel2" && sm && styles.titleActiveMobile
                )}
              >
                {competence}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.skillsWrapper}>
                <div className={styles.skills}>
                  <div
                    className={clsx(
                      styles.skillsTitle,
                      competencesExpanded.name === "hard" &&
                        styles.skillsTitleActive
                    )}
                    onClick={() =>
                      setCompetencesExpanded({
                        name: "hard",
                        data: competences.hard,
                      })
                    }
                  >
                    {hard}
                  </div>
                  <div
                    className={clsx(
                      styles.skillsTitle,
                      competencesExpanded.name === "soft" &&
                        styles.skillsTitleActive
                    )}
                    onClick={() =>
                      setCompetencesExpanded({
                        name: "soft",
                        data: competences.soft,
                      })
                    }
                  >
                    {soft}
                  </div>
                </div>
                <MDXProvider>
                  <MDXRenderer>
                    {competencesExpanded.data.childMdx.body}
                  </MDXRenderer>
                </MDXProvider>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={expanded === "panel3" ? { overflow: "auto" } : ""}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography
                className={clsx(
                  expanded === "panel3" && !sm && styles.titleActive,
                  expanded === "panel3" && sm && styles.titleActiveMobile
                )}
              >
                {evolutions}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MDXProvider>
                <MDXRenderer>{evolution.childMdx.body}</MDXRenderer>
              </MDXProvider>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={expanded === "panel4" ? { overflow: "auto" } : ""}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography
                className={clsx(
                  expanded === "panel4" && !sm && styles.titleActive,
                  expanded === "panel4" && sm && styles.titleActiveMobile
                )}
              >
                {program}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MDXProvider>
                <MDXRenderer>{talent.childMdx.body}</MDXRenderer>
              </MDXProvider>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <div
            style={{ backgroundImage: `url(${file.url})` }}
            className={styles.image}
          />
          <div className={styles.layout} />
          <div className={styles.title}>{title} </div>
          <Link to={localeLink(CONTACT)}>
            <div className={styles.candidat}>
              <div>
                <BorderButton
                  label={consult}
                  color="#fff"
                  width={6}
                  wide={true}
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

JobDescription.propTypes = {
  file: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  modalData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
