import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { UniversPageContext } from "../helpers/pagesContext";
import { UNIVERS } from "../helpers/route";
import UniversScene from "../scenes/Univers/Univers";

export default function Univers(props) {
  return (
    <Layout currentRoute={UNIVERS} data={props.data.contentfulMenuLabel}>
      <Seo
        title="Notre univers"
        description="L'univers de Zenity est une constellation de testeurs Front-End, Fullstack, Automaticiens, CI/CD, Lead QA, products managers, QA Consulting, Services Offshore, garantissant la qualité de vos logiciels"
      />
      <UniversPageContext.Provider value={props.data.contentfulUnivers}>
        <UniversScene />
      </UniversPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulUnivers(node_locale: { eq: $locale }) {
      factoryTitle1
      factoryTitle2
      factoryContent {
        raw
      }
      factorySubContent {
        raw
      }
      factoryBackground1 {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      factoryBackground2 {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      hero {
        file {
          url
          fileName
        }
      }
      vignette {
        title
        image {
          file {
            url
            fileName
          }
        }
        modalData {
          missions {
            childMdx {
              body
            }
          }
          competences {
            hard {
              childMdx {
                body
              }
            }
            soft {
              childMdx {
                body
              }
            }
          }
          evolution {
            childMdx {
              body
            }
          }
          talent {
            childMdx {
              body
            }
          }
        }
        description {
          description
        }
      }
      footer {
        file {
          fileName
          url
        }
      }
      jobDescription
      univers
      contactUs
      talent
      createAdventure
      joinCanopy
      sentenceOne
      sentenceTwo
      sentenceThree
      footerTitle
      footerButtonLabel
      node_locale
      mission
      competence
      hard
      soft
      evolutions
      program
      consult
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
