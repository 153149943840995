// extracted by mini-css-extract-plugin
export var accordion = "JobDescription-module--accordion--cbd3b";
export var candidat = "JobDescription-module--candidat--293cb";
export var close = "JobDescription-module--close--a622b";
export var grid = "JobDescription-module--grid--52449";
export var image = "JobDescription-module--image--362de";
export var imageContainer = "JobDescription-module--imageContainer--f825c";
export var layout = "JobDescription-module--layout--29765";
export var skills = "JobDescription-module--skills--95db9";
export var skillsTitle = "JobDescription-module--skillsTitle--3cd3e";
export var skillsTitleActive = "JobDescription-module--skillsTitleActive--f6aaa";
export var skillsWrapper = "JobDescription-module--skillsWrapper--3b7f8";
export var title = "JobDescription-module--title--24742";
export var titleActive = "JobDescription-module--titleActive--9f763";
export var titleActiveMobile = "JobDescription-module--titleActiveMobile--33dbd";
export var titleMobile = "JobDescription-module--titleMobile--43a0a";