import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OverFooter } from "../../components/OverFooter/OverFooter";
import { UniversPageContext } from "../../helpers/pagesContext";
import { INSIDE_ZENITY } from "../../helpers/route";
import { Board } from "./components/Board/Board";
import { Factory } from "./components/Factory/Factory";
import { Hero } from "./components/Hero/Hero";

export default function Univers() {
  const { footer, footerTitle, footerButtonLabel, node_locale } =
    useContext(UniversPageContext);

  return (
    <>
      <Hero />
      <Board />
      <Factory />
      <OverFooter
        title={footerTitle}
        buttonLink={INSIDE_ZENITY}
        buttonLabel={footerButtonLabel}
        image={footer.file.url}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </>
  );
}
