import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { UniversPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./factory.module.css";

export const Factory = () => {
  const {
    factoryTitle1,
    factoryTitle2,
    factoryContent,
    factorySubContent,
    factoryBackground1,
    factoryBackground2,
    sentenceOne,
    sentenceTwo,
    sentenceThree,
  } = useContext(UniversPageContext);

  return (
    <section
      id="zenity-factory"
      style={{
        backgroundImage: `url(${factoryBackground2.file.url})`,
      }}
      className={styles.root}
    >
      <div className={styles.container}>
        <div className={styles.content} id="zenity-factory-content">
          <SlipTransition lines={[factoryTitle1, factoryTitle2]} />
          <div className={styles.paragraph}>
            <SlideTransition delay={0.5}>
              {renderRichText(factoryContent)}
            </SlideTransition>
            <SlideTransition delay={0.5}>
              {renderRichText(factorySubContent)}
            </SlideTransition>
          </div>
        </div>
        <div className={styles.image}>
          <SlideTransition delay={0.2}>
            <GatsbyImage
              image={factoryBackground1.gatsbyImageData}
              alt="factory"
            />
          </SlideTransition>
        </div>
      </div>
      <div className={styles.quotation}>
        <p>{sentenceOne}</p>
        <p>{sentenceTwo}</p>
        <p>{sentenceThree}</p>
      </div>
    </section>
  );
};
