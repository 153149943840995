import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { SimpleArrowButton } from "../../../../../../components/Button/Button";
import { JobDescription } from "../JobDescription/JobDescription";
import * as styles from "./Vignette.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={600} />;
});

export const Vignette = ({ file, title, description, modalData }) => {
  const small = useMediaQuery("(min-width:575.98px)");

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={styles.contentImage}
        onClick={() => {
          setOpen(true);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <img className={styles.image} src={file.url} alt={file.fileName} />
        <div className={styles.layout} />
        <div className={styles.content}>
          <div className={styles.simpleArrowButton}>
            <SimpleArrowButton
              hovered={small ? hover : true}
              color={{ arrow: "transparent", text: "white" }}
            />
          </div>
          {description && (
            <div className={styles.text}>
              <div>{description}</div>
            </div>
          )}
          <div className={styles.title}>{title}</div>
        </div>
      </div>
      <Dialog
        TransitionComponent={Transition}
        onClose={handleClose}
        open={open}
        fullScreen
        sx={{ zIndex: 1302 }}
        className={styles.dialog}
      >
        <JobDescription
          title={title}
          file={file}
          modalData={modalData}
          handleClose={handleClose}
        />
      </Dialog>
    </>
  );
};

Vignette.propTypes = {
  file: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalData: PropTypes.object.isRequired,
};
