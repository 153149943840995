import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { UniversPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const md = useMediaQuery("(min-width:991.98px)");
  const secondLineStart = 5;
  const { hero, jobDescription, univers } = useContext(UniversPageContext);

  return (
    <section
      style={{
        background: `linear-gradient(180deg, rgba(28, 28, 28, 0.4) 35.83%, rgba(79, 79, 79, 0.2) 114.35%), url(${hero.file.url}) no-repeat center center / cover`,
      }}
      className={styles.root}
    >
      <div className={styles.container}>
        <SlipTransition
          lines={[univers]}
          important={true}
          color="#fff"
          options={{ delay: 0.4, animateY: -150 }}
        />
        {md ? (
          <>
            <SlideTransition delay={0.4}>
              <div className={styles.elements}>
                {jobDescription.slice(0, 4).map((job, i) => (
                  <div key={i}>
                    <h2>
                      <span>{`0${i + 1}`}</span>
                      {job}
                    </h2>
                  </div>
                ))}
              </div>
            </SlideTransition>
            <SlideTransition delay={0.6}>
              <div className={styles.secondsElements}>
                {jobDescription.slice(4, 7).map((job, i) => (
                  <div key={i}>
                    <h2>
                      <span>{`0${i + secondLineStart}`}</span>
                      {job}
                    </h2>
                  </div>
                ))}
              </div>
            </SlideTransition>
          </>
        ) : (
          <SlideTransition thresholds={200} duration={2}>
            <div className={styles.elements}>
              {jobDescription.map((job, i) => (
                <div key={i}>
                  <h2>
                    <span>{`0${i + 1}`}</span>
                    {job}
                  </h2>
                </div>
              ))}
            </div>
          </SlideTransition>
        )}
      </div>
    </section>
  );
};
