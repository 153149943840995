import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { SimpleArrowButton } from "../../../../../../components/Button/Button";
import * as styles from "./Question.module.css";

export const Question = ({ label, text, backgroundColor }) => {
  const [hover, setHover] = useState(false);
  const small = useMediaQuery("(min-width:575.98px)");
  return (
    <div
      className={styles.content}
      style={{ backgroundColor }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.text}>
        <h2 className={styles.textContent}>{text}</h2>
      </div>
      <div className={styles.button}>
        <div className={styles.buttonContent}>
          <SimpleArrowButton
            label={!small ? "" : label}
            hovered={small ? hover : true}
            color={{ arrow: "transparent", text: "white" }}
          />
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};
