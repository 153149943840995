import { Link } from "gatsby";
import React, { useContext } from "react";

import { localeLink } from "../../../../helpers/localeLink";
import { UniversPageContext } from "../../../../helpers/pagesContext";
import { CONTACT } from "../../../../helpers/route";
import * as styles from "./Board.module.css";
import { Question } from "./components/Question/Question";
import { Vignette } from "./components/Vignette/Vignette";

export const Board = () => {
  const { vignette, contactUs, talent, createAdventure, joinCanopy } =
    useContext(UniversPageContext);

  return (
    <div className={styles.root}>
      {vignette.map(
        (
          { image: { file }, description: { description }, title, modalData },
          i,
        ) => (
          <Vignette
            key={i}
            file={file}
            title={title}
            description={description}
            modalData={modalData}
          />
        ),
      )}
      <div>
        <Link to={localeLink(CONTACT)} state={{ RH: true }}>
          <Question label={contactUs} text={talent} backgroundColor="#D4A95E" />
        </Link>
      </div>
      <div>
        <Link to={localeLink(CONTACT)} state={{ CV: true }}>
          <Question
            label={createAdventure}
            text={joinCanopy}
            backgroundColor="#3FC3CC"
          />
        </Link>
      </div>
    </div>
  );
};
